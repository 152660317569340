import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSpecialities } from '../../redux/specialitiesSlice';
import styles from './SearchPlace.module.css'; // Assure-toi de créer un fichier CSS si nécessaire

// Fonction utilitaire pour normaliser une chaîne
const normalizeString = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

const SearchSpeciality = ({ onSelect, openList, setOpenList }) => {
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const { specialities } = useSelector(state => state.specialities);

  useEffect(() => {
    if (!specialities || specialities.length == 0) {
      dispatch(fetchSpecialities());
    }
  }, [dispatch]);

  // Charger toutes les spécialités au montage du composant
  useEffect(() => {
    if (!suggestions || suggestions.length == 0) {
      setSuggestions(specialities);
    }
  }, [specialities]);

  const handleInputChange = e => {
    const value = e.target.value;
    setSearchTerm(value);

    // Filtrer les spécialités par rapport à l'entrée utilisateur
    const filtered = specialities.filter(speciality =>
      normalizeString(speciality.metier).includes(normalizeString(value))
    );
    setSuggestions(filtered);
  };

  const handleSuggestionClick = speciality => {
    setSearchTerm(speciality.metier);
    setSuggestions([]);
    onSelect(speciality); // Passer la spécialité sélectionnée au parent via `onSelect`
  };

  const handleFocus = () => {
    setOpenList('speciality');
  };

  return (
    <div className={styles.searchGeoOrSpe}>
      <input
        type="text"
        placeholder="Spécialité"
        className={styles.searchInput}
        value={searchTerm}
        onChange={handleInputChange}
        onFocus={handleFocus}
      />
      {openList === 'speciality' && suggestions.length > 0 && (
        <ul className={styles.suggestionsList}>
          {suggestions.map(speciality => (
            <li key={speciality.id} className={styles.suggestionItem} onClick={() => handleSuggestionClick(speciality)}>
              {speciality.metier}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

SearchSpeciality.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setOpenList: PropTypes.func.isRequired,
  openList: PropTypes.string
};

export default SearchSpeciality;
