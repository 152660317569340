import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { jwtDecode } from 'jwt-decode';
import { login, logout } from '../services/Api';

// Action pour se connecter
export const userLogin = createAsyncThunk('auth/login', async (user, { rejectWithValue }) => {
  const token = await login(user); // API renvoie directement le token
  if (!token) return rejectWithValue('Connexion échouée');

  localStorage.setItem('jwt', token); // Stocke le token
  return token;
});

// Action pour se déconnecter
export const userLogout = createAsyncThunk('auth/logout', async (_, { rejectWithValue }) => {
  const response = await logout(); // Déconnexion côté API
  if (!response) return rejectWithValue('Déconnexion échouée');

  localStorage.removeItem('jwt'); // Supprime le token du stockage
  return null;
});

// Charger les données depuis localStorage au démarrage
const token = localStorage.getItem('jwt');

const initialState = {
  token: token || null,
  userClaims: token ? jwtDecode(token) : null, // Décoder immédiatement si un token est présent
  isLoading: false,
  error: null
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Login
      .addCase(userLogin.pending, state => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(userLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.token = action.payload;
        state.userClaims = jwtDecode(action.payload); // Décode immédiatement le token
      })
      .addCase(userLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      // Logout
      .addCase(userLogout.fulfilled, state => {
        state.token = null;
        state.userClaims = null;
      });
  }
});

export default authSlice.reducer;
