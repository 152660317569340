// src/store/specialitiesSlice.js
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getSpecialities } from '../services/Api';

// Action asynchrone pour récupérer toutes les spécialités avec images
export const fetchSpecialities = createAsyncThunk('specialities/fetchAll', async () => {
  // Sinon, on fait l'appel à l'API
  const response = await getSpecialities();
  return response;
});

const specialitiesSlice = createSlice({
  name: 'specialities',
  initialState: {
    specialities: [],
    loading: false,
    error: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSpecialities.pending, state => {
        state.loading = true;
      })
      .addCase(fetchSpecialities.fulfilled, (state, action) => {
        state.loading = false;
        state.specialities = action.payload;
      })
      .addCase(fetchSpecialities.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  }
});

export default specialitiesSlice.reducer;
