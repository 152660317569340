import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Footer.module.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <div className={styles.mainFooter}>
        <div className={styles.companyInfo}>
          <img src="/logov2.webp" alt="Logo Proxi Travaux" className={styles.logo} />
          <p>Nous vous accompagnons dans tous vos projets avec des solutions simples et efficaces.</p>
          <p>Proxi Travaux, {currentYear}</p>
        </div>
        <div className={styles.linksContainer}>
          <div className={styles.linksColumn}>
            <h4>Plateforme</h4>
            <ul>
              <li>
                <a href="/sitemap.xml" target="_blank" rel="noopener noreferrer">
                  Sitemap
                </a>
              </li>
              <li>
                <Link to="/about">Nos Services</Link>
              </li>
              {/*<li> TODO : à décommenter quand on aura une page de témoignage
                <Link to="/">Témoignages</Link>
              </li>*/}
            </ul>
          </div>
          <div className={styles.linksColumn}>
            <h4>Entreprise</h4>
            <ul>
              <li>
                <Link to="/about" preventScrollReset>
                  À propos
                </Link>
              </li>
              {/*<li> TODO : à décommenter quand on aura une page de partenaire
                <Link to="/">Partenaires</Link>
              </li>*/}
            </ul>
          </div>
          <div className={styles.linksColumn}>
            <h4>Ressources</h4>
            <ul>
              <li>
                <Link to="/metiers">Métiers</Link>
              </li>
              <li>
                <Link to="/contact">Support</Link>
              </li>
              {/*<li> TODO : à décommenter quand on aura une page de FAQ
                <Link to="/infos-et-aides">FAQ</Link>
              </li>*/}
            </ul>
          </div>
        </div>
      </div>
      <div className={styles.bottomFooter}>
        <p>© {currentYear} Proxi Travaux. Tous droits réservés.</p>
        <div className={styles.legalLinks}>
          <Link to="/cgu">Conditions d&apos;utilisation</Link>
          <Link to="/politique-confidentialite">Politique de confidentialité</Link>
          <Link to="/">Cookies</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
