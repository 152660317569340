import React, { memo } from 'react';
import { Link } from 'react-router-dom';

export const PrivacyPolicy = memo(() => (
  <div>
    <h2>Politique de Confidentialité de Proxi Travaux</h2>

    <h3>Introduction</h3>
    <p>
      Chez Proxi Travaux, nous respectons votre vie privée et nous nous engageons à protéger les informations que vous
      partagez avec nous. Cette politique de confidentialité décrit comment nous collectons, utilisons et protégeons vos
      données personnelles.
    </p>

    <h3>Article 1 – Données collectées</h3>
    <h4>1.1. Données des utilisateurs</h4>
    <p>
      Proxi Travaux ne collecte aucune donnée personnelle des utilisateurs qui se contentent de consulter la plateforme.
      Les visiteurs peuvent naviguer librement sans fournir d&apos;informations personnelles.
    </p>

    <h4>1.2. Données des artisans</h4>
    <p>
      Les artisans qui s&apos;inscrivent sur la plateforme fournissent volontairement des informations nécessaires à la
      création de leur profil professionnel, telles que :
    </p>
    <ul>
      <li>Nom et prénom</li>
      <li>Coordonnées (adresse, téléphone, email)</li>
      <li>Domaines de compétence</li>
      <li>Photos de réalisations</li>
    </ul>

    <h3>Article 2 – Utilisation des données</h3>
    <p>Les données collectées auprès des artisans sont utilisées pour :</p>
    <ul>
      <li>Afficher des profils détaillés sur la plateforme</li>
      <li>Faciliter la mise en relation avec des clients potentiels</li>
      <li>Améliorer nos services</li>
    </ul>

    <h3>Article 3 – Partage des données</h3>
    <p>
      Les informations fournies par les artisans sont publiquement accessibles sur la plateforme. Aucune donnée
      personnelle des artisans ou des utilisateurs n&apos;est vendue ou partagée avec des tiers non autorisés.
    </p>

    <h3>Article 4 – Sécurité des données</h3>
    <p>
      Proxi Travaux met en œuvre des mesures techniques et organisationnelles pour protéger vos données contre tout
      accès non autorisé, modification, divulgation ou destruction.
    </p>

    <h3>Article 5 – Droits des artisans</h3>
    <p>
      Conformément à la réglementation en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD),
      les artisans disposent des droits suivants :
    </p>
    <ul>
      <li>Accéder à leurs données personnelles</li>
      <li>Rectifier des informations inexactes</li>
      <li>Demander la suppression de leur profil</li>
      <li>Limiter le traitement de leurs données</li>
    </ul>
    <p>
      Pour exercer ces droits, veuillez nous contacter <Link to="/contact">via ce formulaire</Link>.
    </p>

    <h3>Article 6 – Cookies</h3>
    <p>
      La plateforme Proxi Travaux utilise des cookies pour améliorer l&apos;expérience utilisateur et analyser
      l&apos;utilisation du site. Ces cookies ne collectent aucune donnée personnelle identifiable.
    </p>

    <h3>Article 7 – Modifications de la politique de confidentialité</h3>
    <p>
      Proxi Travaux se réserve le droit de modifier cette politique de confidentialité à tout moment. Toute mise à jour
      sera publiée sur cette page.
    </p>

    <h3>Article 8 – Contact</h3>
    <p>
      Pour toute question ou demande concernant cette politique de confidentialité, vous pouvez nous écrire{' '}
      <Link to="/contact">ici</Link>.
    </p>
  </div>
));

PrivacyPolicy.displayName = 'PrivacyPolicy';

export default PrivacyPolicy;
