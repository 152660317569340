import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styles from './Propos.module.css';

const Propos = memo(() => {
  return (
    <section className={styles.proposSection}>
      <h1 className={styles.title}>
        À propos de <strong>Proxi Travaux</strong>
      </h1>

      {/* Mission */}
      <div className={styles.missionSection}>
        <h2 className={styles.subtitle}>🚀 Notre Mission</h2>
        <p className={styles.text}>
          Faciliter la mise en relation entre <strong>artisans</strong> et <strong>particuliers</strong>.
        </p>
        <ul className={styles.list}>
          <li>Accès simplifié à des professionnels qualifiés</li>
          <li>Service sans prise de tête ni frais cachés</li>
          <li>Un accompagnement pour des projets réussis</li>
        </ul>
      </div>

      {/* Expertise */}
      <div className={styles.expertiseSection}>
        <h2 className={styles.subtitle}>🎯 Notre Expertise</h2>
        <p className={styles.text}>
          Une plateforme pensée pour être à la fois <strong>innovante</strong> et <strong>adaptée</strong>.
        </p>
        <ul className={styles.list}>
          <li>Des outils performants et faciles à utiliser</li>
          <li>Une expérience fluide pour artisans et particuliers</li>
          <li>Un réseau d’experts qualifiés, toujours en croissance</li>
          <li>Une équipe dédiée à l’amélioration continue de nos services</li>
        </ul>
      </div>

      {/* Valeurs */}
      <div className={styles.valuesSection}>
        <h2 className={styles.subtitle}>💡 Nos Valeurs</h2>
        <ul className={styles.list}>
          <li>
            <strong>Transparence :</strong> Toutes les infos sur les artisans sont accessibles.
          </li>
          <li>
            <strong>Proximité :</strong> Favoriser les connexions locales et humaines.
          </li>
          <li>
            <strong>Qualité :</strong> Sélection rigoureuse des artisans pour garantir un travail soigné.
          </li>
        </ul>
      </div>

      {/* Histoire & Équipe */}
      <div className={styles.historyTeamSection}>
        <h2 className={styles.subtitle}>📖 L&apos;histoire et l&apos;équipe de Proxi Travaux</h2>

        <p className={styles.text}>
          En 2024, <strong>deux passionnés</strong> ont combiné leurs compétences : l&apos;un dans l&apos;immobilier,
          l&apos;autre dans le digital.
        </p>

        <p className={styles.text}>
          Leur objectif était clair : simplifier la mise en relation entre <strong>artisans</strong> et{' '}
          <strong>particuliers</strong>, tout en apportant une solution innovante aux défis du secteur.
        </p>

        <ul className={styles.list}>
          <li>Proposer une plateforme intuitive et performante</li>
          <li>Mettre en avant les compétences des artisans et les besoins des particuliers</li>
          <li>Créer un réseau solide, basé sur la confiance et la transparence</li>
        </ul>

        <p className={styles.text}>
          Notre équipe, avec son expertise croisée, œuvre chaque jour pour offrir une expérience sans faille aux
          artisans et particuliers. <strong>Notre ambition</strong> : Être la référence en France pour la mise en
          relation entre artisans et particuliers, avec une plateforme évolutive et accessible.
        </p>
      </div>

      {/* Appel à l'action */}
      <div className={styles.joinSection}>
        <p className={styles.text}>
          Vous partagez notre vision ? Rejoignez-nous pour construire l&apos;avenir de l&apos;artisanat en France.
        </p>
        <Link to="/join" className={styles.joinButton}>
          Nous Rejoindre
        </Link>
      </div>
    </section>
  );
});

Propos.displayName = 'Propos';

export default Propos;
