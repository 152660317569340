import React, { memo, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendMailToSupport } from '../../services/Api';
import styles from './ContactForm.module.css';

const ContactForm = memo(() => {
  const [formValues, setFormValues] = useState({
    name: '',
    email: '',
    phone: '',
    role: 'Particulier', // Par défaut
    message: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = e => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value
    });
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    const emailDto = {
      nom: formValues.name,
      email: formValues.email,
      tel: formValues.phone,
      role: formValues.role,
      message: formValues.message
    };

    await sendMailToSupport(emailDto);
    toast.success('Message envoyé avec succès !');
    setFormValues({
      name: '',
      email: '',
      phone: '',
      role: 'Particulier',
      message: ''
    });

    setIsLoading(false);
  };

  return (
    <section className={styles.contactSection}>
      <div className={styles.textContainer}>
        <h1 className={styles.title}>PROXI TRAVAUX</h1>
        <p>
          <strong>Une question ou un projet en tête ?</strong>
          <br />
          Remplissez le formulaire ci-contre et notre équipe vous accompagnera à chaque étape. Nous sommes là pour
          répondre à vos questions et vous aider à trouver l’artisan idéal pour{' '}
          <strong>concrétiser votre projet</strong>.
        </p>

        {/* TODO : A décommenter une fois les réseaux créés
          <div className={styles.socialMediaContainer}>
              <a href="/" target="_blank" rel="noopener noreferrer">
                <img src="assets/facebook2.webp" alt="Facebook" className={styles.socialMediaIcon} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                <img src="assets/instagram.webp" alt="Twitter" className={styles.socialMediaIcon} />
              </a>
            </div>
        */}
        <div className={styles.socialMediaContainer}>
          <p className={styles.comingSoonText}>Nos réseaux sociaux arrivent bientôt !</p>
          <div className={styles.iconsContainer}>
            <img
              src="assets/facebook2.webp"
              alt="Facebook (bientôt disponible)"
              className={`${styles.socialMediaIcon} ${styles.disabled}`}
            />
            <img
              src="assets/instagram.webp"
              alt="Instagram (bientôt disponible)"
              className={`${styles.socialMediaIcon} ${styles.disabled}`}
            />
          </div>
        </div>
      </div>

      <div className={styles.formContainer}>
        <h2 className={styles.formTitle}>CONTACTEZ NOUS</h2>
        <form onSubmit={handleSubmit} className={styles.form}>
          <input
            type="text"
            id="name"
            placeholder="Votre nom"
            name="name"
            data-testid="name-input"
            value={formValues.name}
            onChange={handleInputChange}
            className={styles.contactInput}
            required
          />
          <input
            type="email"
            id="email"
            placeholder="Votre email"
            name="email"
            data-testid="email-input"
            value={formValues.email}
            onChange={handleInputChange}
            className={styles.contactInput}
            required
          />
          <input
            type="tel"
            id="phone"
            placeholder="Votre téléphone"
            name="phone"
            data-testid="tel-input"
            value={formValues.phone}
            onChange={handleInputChange}
            className={styles.contactInput}
            required
          />
          <select
            name="role"
            value={formValues.role}
            onChange={handleInputChange}
            className={styles.contactInput}
            required
          >
            <option value="Particulier">Particulier</option>
            <option value="Professionnel">Professionnel</option>
          </select>
          <textarea
            id="message"
            placeholder="Votre message"
            name="message"
            data-testid="mess-input"
            value={formValues.message}
            onChange={handleInputChange}
            className={styles.contactInput}
            required
          ></textarea>
          <button type="submit" className={styles.submitButton} disabled={isLoading}>
            {isLoading ? 'Envoi en cours...' : 'ENVOYER LE FORMULAIRE'}
          </button>
        </form>
      </div>
    </section>
  );
});

ContactForm.displayName = 'ContactForm';

export default ContactForm;
