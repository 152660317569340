import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './HomeBackoffice.module.css';

const AddUserForm = ({ onSubmit, user }) => {
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [telephone, setTelephone] = useState('');
  const [role, setRole] = useState('ADMIN'); // Valeur par défaut
  const [personnalDataToShow, setPersonnalDataToShow] = useState(''); // Valeur par défaut
  const [showPassword, setShowPassword] = useState(false);

  // Pré-remplir le formulaire si un utilisateur est passé en props (modification)
  useEffect(() => {
    if (user) {
      setEmail(user.email);
      setUsername(user.username);
      setPassword(user.password);
      setTelephone(user.telephone);
      setRole(user.role);
      setPersonnalDataToShow(user.personnalDataToShow);
    } else {
      setEmail('');
      setUsername('');
      setPassword('');
      setTelephone('');
      setRole('ROLE_ADMIN');
      setPersonnalDataToShow('');
    }
  }, [user]);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({ email, username, password, telephone, role, personnalDataToShow });
    setEmail('');
    setUsername('');
    setPassword('');
    setTelephone('');
    setRole('ROLE_ADMIN');
    setPersonnalDataToShow('ALL');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="email">Email :</label>
        <input type="text" id="email" value={email} onChange={e => setEmail(e.target.value)} required />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="username">Nom d&apos;utilisateur :</label>
        <input type="text" id="username" value={username} onChange={e => setUsername(e.target.value)} required />
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="telephone">Numéro de téléphone :</label>
        <input type="text" id="telephone" value={telephone} onChange={e => setTelephone(e.target.value)} required />
      </div>
      {password && (
        <div className={styles.formGroup}>
          <label htmlFor="password">Mot de passe :</label>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              required
            />
            <span onClick={() => setShowPassword(prev => !prev)} style={{ marginLeft: '5px', cursor: 'pointer' }}>
              {showPassword ? '👁️' : '🙈'}
            </span>
          </div>
        </div>
      )}
      <div className={styles.formGroup}>
        <label htmlFor="role">Rôle :</label>
        <select id="role" value={role} onChange={e => setRole(e.target.value)} required>
          <option value="ROLE_ADMIN">Admin</option>
          <option value="ROLE_ARTISAN">Artisan</option>
          <option value="ROLE_PARTICULIER">Particulier</option>
        </select>
      </div>
      <div className={styles.formGroup}>
        <label htmlFor="personnalDataToShow">Informations personnelles à afficher (pour les artisans) :</label>
        <select
          id="personnalDataToShow"
          value={personnalDataToShow}
          onChange={e => setPersonnalDataToShow(e.target.value)}
        >
          <option value=""></option>
          <option value="ALL">Tout (mail et téléphone)</option>
          <option value="TEL">Numéro de téléphone</option>
          <option value="MAIL">Email</option>
          <option value="NOTHING">Rien</option>
        </select>
      </div>
      <button className={styles.buttonList} type="submit">
        {user ? 'Modifier' : 'Ajouter'} l&apos;utilisateur
      </button>
    </form>
  );
};

AddUserForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  user: PropTypes.shape({
    email: PropTypes.string,
    username: PropTypes.string,
    password: PropTypes.string,
    telephone: PropTypes.string,
    role: PropTypes.string,
    personnalDataToShow: PropTypes.string
  })
};

export default AddUserForm;
