import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { uploadFileSpe } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

const API_URL = import.meta.env.VITE_API_URL;

const AddSpecialityForm = ({ onSubmit, speciality }) => {
  const [metier, setMetier] = useState('');
  const [pathImg, setPathImg] = useState(null);

  // Pré-remplir le formulaire si une spécialité est passée en props (modification)
  useEffect(() => {
    if (speciality) {
      setMetier(speciality.metier);
      setPathImg(speciality.pathImg);
    } else {
      setMetier('');
      setPathImg('');
    }
  }, [speciality]);

  const handleSubmit = e => {
    e.preventDefault();
    onSubmit({ id: speciality ? speciality.id : null, metier, pathImg });
    setMetier('');
    setPathImg('');
  };

  const handleImageDrop = e => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("La taille de l'image ne doit pas dépasser 2 Mo.");
        return;
      }

      // Appel API pour uploader le fichier et récupérer le chemin
      const formData = new FormData();
      formData.append('file', file);
      uploadFile(formData);
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      // Appel API pour uploader le fichier et récupérer le chemin
      const formData = new FormData();
      formData.append('file', file);
      uploadFile(formData);
    }
  };

  const uploadFile = async formData => {
    // Appel à l'API pour uploader le fichier sur le serveur
    try {
      const filePath = await uploadFileSpe(formData);
      setPathImg(filePath); // Met à jour l'état avec le chemin du fichier
    } catch (error) {
      console.log(error);
      toast.error('Erreur lors du téléchargement du fichier.');
    }
  };

  const handleRemoveImage = () => {
    setPathImg('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className={styles.formGroup}>
        <label htmlFor="metier">Métier :</label>
        <input type="text" id="metier" value={metier} onChange={e => setMetier(e.target.value)} required />
      </div>

      <div
        onDrop={handleImageDrop}
        onDragOver={e => e.preventDefault()}
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          textAlign: 'center',
          marginBottom: '20px'
        }}
      >
        {pathImg ? (
          <div>
            <img
              src={`${API_URL}/${pathImg}`}
              alt="Aperçu"
              style={{ maxWidth: '100%', maxHeight: '150px', marginBottom: '10px' }}
            />
            <button className={styles.buttonList} onClick={handleRemoveImage}>
              Supprimer l&apos;image
            </button>
          </div>
        ) : (
          <p></p>
        )}
        <input
          type="file"
          accept="image/*" // Limite le type de fichiers à des images
          onChange={handleFileChange}
          style={{ display: 'none' }}
          id="file-input"
        />
        <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
          Déposez une image ici ou cliquez pour sélectionner un fichier
        </label>
      </div>

      <button className={styles.buttonList} type="submit">
        {speciality ? 'Modifier' : 'Ajouter'} la spécialité
      </button>
    </form>
  );
};

AddSpecialityForm.propTypes = {
  onSubmit: PropTypes.func.isRequired, // `onSubmit` doit être une fonction
  speciality: PropTypes.shape({
    id: PropTypes.number,
    metier: PropTypes.string,
    pathImg: PropTypes.string
  })
};

export default AddSpecialityForm;
