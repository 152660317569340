import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { deleteArtisan, getArtisans, postArtisan, updateArtisan } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

import AddArtisanForm from './AddArtisanForm';
import ModalDetailArtisan from './ModalDetailArtisan';
import ModalNotesArtisan from './ModalNotesArtisan';

const ListArtisan = () => {
  const [artisans, setArtisans] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedArtisan, setSelectedArtisan] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotesModalOpen, setIsNotesModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); // État pour contrôler l'affichage de la popup d'ajout

  const fetchArtisans = async () => {
    const data = await getArtisans();
    setArtisans(data);
  };

  useEffect(() => {
    fetchArtisans();
  }, []);

  const handleSubmit = async formData => {
    if (formData.id) {
      await updateArtisan(formData.id, formData);
    } else {
      await postArtisan(formData);
    }
    fetchArtisans();
    setIsAddModalOpen(false); // Ferme la popup après la soumission
    setSelectedArtisan(null);
  };

  const handleOpenModal = artisan => {
    setSelectedArtisan(artisan);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedArtisan(null);
  };

  const handleOpenNotesModal = artisan => {
    setSelectedArtisan(artisan);
    setIsNotesModalOpen(true);
  };

  const handleCloseNotesModal = () => {
    setIsNotesModalOpen(false);
    setSelectedArtisan(null);
  };

  const handleDelete = async artisanId => {
    await deleteArtisan(artisanId);
    fetchArtisans();
  };

  const handleEdit = artisan => {
    setSelectedArtisan(artisan);
    setIsAddModalOpen(true); // Ouvre la popup pour modifier l'artisan
  };

  const filteredArtisans = artisans.filter(
    artisan =>
      artisan.entrepriseNom.toLowerCase().includes(searchQuery.toLowerCase()) ||
      artisan.adresse.ville.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className={styles.container}>
      <h1>Liste des Artisans</h1>

      {/* Lien pour revenir à la page d'accueil */}
      <Link to="/backoffice/admin/home">
        <button className={styles.buttonList}>Retour à l&apos;accueil</button>
      </Link>

      <button
        className={styles.buttonList}
        onClick={() => {
          setIsAddModalOpen(true);
        }}
      >
        + Ajouter un Artisan
      </button>

      {/* Afficher le formulaire d'ajout/modification dans une popup */}
      {isAddModalOpen && (
        <div className={styles.modalOverlay} onClick={() => setIsAddModalOpen(false)}>
          <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
            <h2>{selectedArtisan ? 'Modifier Artisan' : 'Ajouter un Nouvel Artisan'}</h2>
            <AddArtisanForm onSubmit={handleSubmit} existingArtisan={selectedArtisan} />
            <button className={styles.buttonList} onClick={() => setIsAddModalOpen(false)}>
              Fermer
            </button>
          </div>
        </div>
      )}

      <input
        type="text"
        placeholder="Rechercher un artisan..."
        value={searchQuery}
        onChange={e => setSearchQuery(e.target.value)}
        style={{ margin: '20px 0', padding: '10px', width: '100%' }}
      />

      {filteredArtisans.length > 0 ? (
        <ul>
          {filteredArtisans.map(artisan => (
            <li key={artisan.id}>
              {artisan.entrepriseNom} - {artisan.adresse.ville} ({artisan.adresse.codePostal})
              <div>
                <button className={styles.buttonList} onClick={() => handleOpenModal(artisan)}>
                  Détails
                </button>
                <button className={styles.buttonList} onClick={() => handleEdit(artisan)}>
                  Modifier
                </button>
                <button className={styles.buttonList} onClick={() => handleOpenNotesModal(artisan)}>
                  Voir les notes
                </button>
                <button className={styles.buttonList} onClick={() => handleDelete(artisan.id)}>
                  Supprimer
                </button>
                {/* Cette page est à debugger : elle est cependante inutile puisqu'on peut passer directement par "Mon profil pro"
                  <Link to={`/backoffice/admin/artisan/${artisan.id}`}>
                    <button className={styles.buttonList}>Informations détaillées</button>
                  </Link>
                */}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>Aucun artisan disponible pour l&apos;instant.</p>
      )}

      {isModalOpen && selectedArtisan && (
        <ModalDetailArtisan isOpen={isModalOpen} onClose={handleCloseModal} artisan={selectedArtisan} />
      )}

      {isNotesModalOpen && selectedArtisan && (
        <ModalNotesArtisan isOpen={isNotesModalOpen} onClose={handleCloseNotesModal} artisan={selectedArtisan} />
      )}
    </div>
  );
};

export default ListArtisan;
