import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { userLogout } from '../redux/authSlice';

const PrivatePages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { userClaims } = useSelector(state => state.auth);

  useEffect(() => {
    if (userClaims) {
      const currentTime = Math.floor(Date.now() / 1000);

      // Si le token est expiré, déconnecter l'utilisateur
      if (userClaims.exp < currentTime) {
        dispatch(userLogout()); // Déconnexion si expiré
      }
    }
  }, [dispatch, userClaims]);

  if (!userClaims && location.pathname.includes('backoffice')) {
    return <Navigate to="/backoffice" />;
  } else if (!userClaims) {
    return <Navigate to="/admin" />;
  } else {
    return <Outlet />;
  }
};

export default PrivatePages;
