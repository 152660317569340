import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo, useState } from 'react';
import ReactStars from 'react-rating-stars-component';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { fetchArtisanDetails } from '../../redux/artisansDetailsSlice';
import { userLogout } from '../../redux/authSlice';
import { getArtisanById, getNotesByArtisanId, getSpecialityByArtisanId, getUserById } from '../../services/Api';
import styles from './ArtisanDetail.module.css';

const API_URL = import.meta.env.VITE_API_URL;

// Modal pour afficher les détails des avis
const Modal = memo(({ isOpen, closeModal, notes }) => {
  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={closeModal}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2>Tous les Avis</h2>
        <button className={styles.closeButton} onClick={closeModal}>
          X
        </button>
        <NotesList notes={notes} />
      </div>
    </div>
  );
});

Modal.displayName = 'Modal';
Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      commentaire: PropTypes.string.isRequired,
      nombreEtoiles: PropTypes.number.isRequired
    })
  ).isRequired
};

const NoteItem = memo(({ note }) => (
  <li key={note.id} className={styles.noteItem}>
    <ReactStars count={5} value={note.nombreEtoiles || 5} size={24} edit={false} activeColor="#ffd700" />
    <p>
      <strong>Commentaire:</strong> {note.commentaire}
    </p>
  </li>
));

NoteItem.displayName = 'NoteItem';
NoteItem.propTypes = {
  note: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nombreEtoiles: PropTypes.number,
    commentaire: PropTypes.string.isRequired
  }).isRequired
};

const NotesList = memo(({ notes }) => (
  <ul className={styles.notesList}>
    {notes.map(note => (
      <NoteItem key={note.id} note={note} />
    ))}
  </ul>
));

NotesList.displayName = 'NotesList';
NotesList.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      nombreEtoiles: PropTypes.number,
      commentaire: PropTypes.string.isRequired
    })
  ).isRequired
};

const LocationInfo = memo(({ artisan }) => (
  <section className={styles.locationInfo}>
    <p>
      <strong>Adresse:</strong> {artisan.adresse.rue}, {artisan.adresse.codePostal} {artisan.adresse.ville},{' '}
      {artisan.adresse.pays}
    </p>
    <p>
      <strong>Rayon d&apos;activité:</strong> {artisan.rayonActivite} km
    </p>
  </section>
));

LocationInfo.displayName = 'LocationInfo';
LocationInfo.propTypes = {
  artisan: PropTypes.shape({
    adresse: PropTypes.shape({
      rue: PropTypes.string.isRequired,
      codePostal: PropTypes.string.isRequired,
      ville: PropTypes.string.isRequired,
      pays: PropTypes.string.isRequired
    }).isRequired,
    rayonActivite: PropTypes.string.isRequired
  }).isRequired
};

const GestionImage = ({ title, images, onImageClick }) => {
  if (!images.length) return null;

  return (
    <section className={styles.portfolio}>
      <h2>{title}</h2>
      <div className={styles.portfolioImages}>
        {images.map((imageDetail, index) => (
          <img
            key={imageDetail.id}
            src={`${API_URL}/${imageDetail.pathImg}`}
            alt={`${title} ${index + 1}`}
            className={styles.portfolioImage}
            onClick={() => onImageClick(index)}
            loading="lazy"
          />
        ))}
      </div>
    </section>
  );
};

GestionImage.propTypes = {
  title: PropTypes.string.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      pathImg: PropTypes.string
    })
  ).isRequired,
  onImageClick: PropTypes.func.isRequired
};

const RatingSection = memo(({ averageRating, toggleModal, isModalOpen, notes }) => {
  if (averageRating <= 0) return null; // Ne rien afficher si la note est inférieure ou égale à 0

  return (
    <section className={styles.ratingSection}>
      <h2>Note Moyenne</h2>
      <ReactStars count={5} value={averageRating || 5} size={24} isHalf={true} edit={false} activeColor="#ffd700" />
      <p>{averageRating.toFixed(1)} / 5</p>
      <button className={styles.viewAllReviewsButton} onClick={toggleModal}>
        Voir tous les avis
      </button>
      <Modal isOpen={isModalOpen} closeModal={toggleModal} notes={notes} />
    </section>
  );
});

RatingSection.displayName = 'RatingSection';
RatingSection.propTypes = {
  averageRating: PropTypes.number,
  toggleModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      commentaire: PropTypes.string.isRequired,
      nombreEtoiles: PropTypes.number.isRequired
    })
  )
};

const ArtisanHeader = memo(({ artisan, userClaims, artisanId, mainImage, user }) => (
  <header className={styles.header}>
    <div className={styles.headerLine}>
      {mainImage && (
        <img src={`${API_URL}/${mainImage.pathImg}`} alt="Présentation de l'artisan" className={styles.mainImage} />
      )}
      <h1 className={styles.entrepriseNom}>{artisan.entrepriseNom}</h1>
      {userClaims && (userClaims.role === 'ROLE_ADMIN' || userClaims.artisanId === Number(artisanId)) && (
        <Link to={`/admin/artisan/${artisanId}`}>EDITER LE PROFIL</Link>
      )}
    </div>
    <div className={styles.basicInfo}>
      {artisan.anneeExperience && (
        <p>
          <strong>Années d&apos;expérience:</strong> {artisan.anneeExperience}
        </p>
      )}
      {user && user.personnalDataToShow && (
        <>
          {(user.personnalDataToShow === 'TEL' || user.personnalDataToShow === 'ALL') && user.telephone && (
            <p>
              <strong>Téléphone:</strong> {user.telephone}
            </p>
          )}
          {(user.personnalDataToShow === 'MAIL' || user.personnalDataToShow === 'ALL') && user.email && (
            <p>
              <strong>Email :</strong> {user.email}
            </p>
          )}
        </>
      )}
      <p>
        <strong>SIRET:</strong> {artisan.siret}
      </p>
      <p>
        <strong>SIREN:</strong> {artisan.siren}
      </p>
    </div>
  </header>
));

ArtisanHeader.displayName = 'ArtisanHeader';
ArtisanHeader.propTypes = {
  artisan: PropTypes.shape({
    entrepriseNom: PropTypes.string.isRequired,
    anneeExperience: PropTypes.string,
    siret: PropTypes.string.isRequired,
    siren: PropTypes.string.isRequired
  }),
  userClaims: PropTypes.shape({
    role: PropTypes.string.isRequired,
    artisanId: PropTypes.number
  }),
  artisanId: PropTypes.string,
  mainImage: PropTypes.shape({
    pathImg: PropTypes.string.isRequired
  }),
  user: PropTypes.shape({
    personnalDataToShow: PropTypes.string,
    telephone: PropTypes.string,
    email: PropTypes.string
  })
};

const ArtisanDetail = ({ artisanId }) => {
  const dispatch = useDispatch();

  const [artisan, setArtisan] = useState(null);
  const [user, setUser] = useState(null);
  const [specialites, setSpecialites] = useState([]);
  const [notes, setNotes] = useState([]);
  const [mainImage, setMainImage] = useState(null);
  const [description, setDescription] = useState(null);
  const [secondaryImages, setSecondaryImages] = useState([]);
  const [normeImages, setNormeImages] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [secondaryPhotoIndex, setSecondaryPhotoIndex] = useState(-1);
  const [normePhotoIndex, setNormePhotoIndex] = useState(-1);

  // Récupération des états globaux depuis Redux
  const { userClaims } = useSelector(state => state.auth);
  const { artisanDetails } = useSelector(state => state.artisanDetails);

  useEffect(() => {
    if (userClaims) {
      const currentTime = Math.floor(Date.now() / 1000);

      // Si le token est expiré, déconnecter l'utilisateur
      if (userClaims.exp < currentTime) {
        dispatch(userLogout()); // Déconnexion si expiré
      }
    }

    dispatch(fetchArtisanDetails(artisanId));
  }, [dispatch, userClaims, artisanId]);

  useEffect(() => {
    const initializeData = async () => {
      const [arts, spes, nots] = await Promise.all([
        getArtisanById(artisanId),
        getSpecialityByArtisanId(artisanId),
        getNotesByArtisanId(artisanId)
      ]);

      setArtisan(arts);
      setSpecialites(spes);
      setNotes(nots);
    };

    if (!artisan) {
      initializeData();
    }
  }, [artisanId]);

  useEffect(() => {
    const initializeUser = async () => {
      const use = await getUserById(artisan.userId);
      setUser(use);
    };
    if (!user && artisan) {
      initializeUser();
    }
  }, [artisan]);

  const averageRating = useMemo(() => {
    if (notes.length === 0) return 0;
    return notes.reduce((acc, note) => acc + note.nombreEtoiles, 0) / notes.length;
  }, [notes]);

  useEffect(() => {
    if (artisanDetails.length > 0) {
      setMainImage(artisanDetails.find(detail => detail.type === 'IMG_PRINCIPALE'));
      setDescription(artisanDetails.find(detail => detail.type === 'DESCRIPTION'));
      setSecondaryImages(artisanDetails.filter(detail => detail.type === 'IMG_REALISATION'));
      setNormeImages(artisanDetails.filter(detail => detail.type === 'NORME'));
    } else {
      setMainImage(null);
      setDescription(null);
      setSecondaryImages([]);
      setNormeImages([]);
    }
  }, [artisanDetails]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      {/* Fil d'Ariane */}
      <div className={styles.breadcrumb}>
        <Link to="/" className={styles.breadcrumbLink}>
          Accueil
        </Link>{' '}
        &gt;{' '}
        <Link to="/metiers" className={styles.breadcrumbLink}>
          Métiers
        </Link>{' '}
        &gt; <span className={styles.breadcrumbCurrent}>{artisan?.entrepriseNom || 'Chargement...'}</span>
      </div>

      <div className={styles.cvContainer}>
        {!artisan || !user ? (
          <div>Chargement des données de l&apos;artisan...</div>
        ) : (
          <>
            <ArtisanHeader
              artisan={artisan}
              userClaims={userClaims}
              artisanId={artisanId}
              mainImage={mainImage}
              user={user}
            />

            <LocationInfo artisan={artisan} />

            <RatingSection
              averageRating={averageRating}
              toggleModal={toggleModal}
              isModalOpen={isModalOpen}
              notes={notes}
            />

            {description?.description && (
              <section className={styles.descriptionSection}>
                <h2>À propos de l&apos;artisan</h2>
                <p>{description.description}</p>
              </section>
            )}

            <section className={styles.specialties}>
              <h2>Spécialités</h2>
              <ul>
                {specialites.map(specialite => (
                  <li key={specialite.id}>{specialite.metier}</li>
                ))}
              </ul>
            </section>

            {normeImages.length > 0 && (
              <GestionImage
                title="Normes Acquises"
                images={normeImages}
                onImageClick={index => setNormePhotoIndex(index)}
              />
            )}

            {secondaryImages.length > 0 && (
              <GestionImage
                title="Galerie de Réalisations"
                images={secondaryImages}
                onImageClick={index => setSecondaryPhotoIndex(index)}
              />
            )}
          </>
        )}
      </div>

      <Lightbox
        open={!!secondaryImages[secondaryPhotoIndex]}
        close={() => setSecondaryPhotoIndex(-1)}
        slides={secondaryImages.map(image => ({
          src: `${API_URL}/${image.pathImg}`,
          alt: image.type || "Image de l'artisan",
          loading: 'lazy'
        }))}
        index={secondaryPhotoIndex}
        onIndexChange={setSecondaryPhotoIndex}
      />

      <Lightbox
        open={!!normeImages[normePhotoIndex]}
        close={() => setNormePhotoIndex(-1)}
        slides={normeImages.map(image => ({
          src: `${API_URL}/${image.pathImg}`,
          alt: image.type || "Image de l'artisan",
          loading: 'lazy'
        }))}
        index={normePhotoIndex}
        onIndexChange={setNormePhotoIndex}
      />
    </>
  );
};

ArtisanDetail.propTypes = {
  artisanId: PropTypes.string.isRequired
};

export default ArtisanDetail;
