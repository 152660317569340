import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import 'leaflet/dist/leaflet.css';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaLocationArrow, FaTimes } from 'react-icons/fa';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchSpecialities } from '../../redux/specialitiesSlice';
import { getArtisans } from '../../services/Api';
import { getSuggestionsFromDataGouv } from '../../services/Client';
import ArtisanPopup from './ArtisanPopup';
import styles from './LeafletMap.module.css';
import RecenterAutomatically from './RecenterAutomatically';

// Icône pour les artisans si nécessaire
const artisanIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41]
});

const normalizeString = str => {
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};

const LeafletMap = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const containerRef = useRef(null);

  const queryParams = new URLSearchParams(location.search);
  const lat = queryParams.get('lat');
  const lon = queryParams.get('lon');

  const { specialities } = useSelector(state => state.specialities);

  const [artisans, setArtisans] = useState([]);
  const [filteredArtisans, setFilteredArtisans] = useState([]);
  const [searchType, setSearchType] = useState('metier');
  const [selectedSpeciality, setSelectedSpeciality] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [suggestionsLoc, setSuggestionsLoc] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [center, setCenter] = useState([45.150002, 1.53333]); // Coordonnées par défaut de Brive

  const hasShownToast = useRef(false); // On n'affiche pas le toast plusieurs fois même si on passe plusieurs fois par le useEffect

  const filteredSpecialities = useMemo(() => {
    if (specialities) {
      return specialities.filter(
        speciality =>
          normalizeString(speciality.metier).includes(normalizeString(searchTerm)) &&
          speciality.id !== selectedSpeciality
      );
    }
    return [];
  }, [specialities, searchTerm, selectedSpeciality]);

  useEffect(() => {
    if (!specialities || specialities.length === 0) {
      dispatch(fetchSpecialities());
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchArtisans = async () => {
      const artisansRes = await getArtisans(); // Appel API pour obtenir les artisans
      setArtisans(artisansRes);

      const metierFromQuery = queryParams.get('metier');
      if (metierFromQuery) {
        setSelectedSpeciality(parseInt(metierFromQuery));
        // Filtre les artisans en fonction de la spécialité sélectionnée
        const filteredArtisans = artisansRes.filter(artisan => artisan.specialites.includes(parseInt(metierFromQuery)));
        setFilteredArtisans(filteredArtisans);
      } else {
        setFilteredArtisans(artisansRes);
      }
    };

    if (!artisans || artisans.length === 0) {
      fetchArtisans();
      setLocationBasedOnSearch();
    }
  }, [queryParams]);

  // Fermer les listes déroulantes si on clique en dehors
  useEffect(() => {
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const setLocationBasedOnSearch = useCallback(() => {
    if (lat && lon) {
      setCenter([parseFloat(lat), parseFloat(lon)]);
    } else if (navigator.geolocation) {
      recenterMapOnLocation();
    }
  });

  const recenterMapOnLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          const { latitude, longitude } = position.coords;
          setCenter([latitude, longitude]);
        },
        error => {
          if (!hasShownToast.current) {
            toast.info('La géolocalisation est impossible');
            hasShownToast.current = true;
          }
          console.error('Erreur de localisation :', error);
        }
      );
    }
  });

  const handleSpecialityChange = useCallback(
    id => {
      const isDeselection = selectedSpeciality === id;
      setSelectedSpeciality(isDeselection ? null : id);
      setFilteredArtisans(artisans.filter(artisan => isDeselection || artisan.specialites.includes(id)));
      setIsFocused(false);
    },
    [selectedSpeciality, artisans]
  );

  const handleInputLocChange = useCallback(e => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim().length < 3) {
      setSuggestionsLoc([]);
      return;
    }

    clearTimeout(window.fetchTimeout);
    window.fetchTimeout = setTimeout(async () => {
      setLoading(true);
      const results = await getSuggestionsFromDataGouv(value);
      setSuggestionsLoc(results);
      setLoading(false);
    }, 300);
  });

  const handleSuggestionClick = useCallback(suggestion => {
    setSearchTerm('');
    setSuggestionsLoc([]);
    setIsFocused(false);
    setCenter([suggestion.geometry.coordinates[1], suggestion.geometry.coordinates[0]]);
  });

  const changeType = useCallback(e => {
    setSearchType(e.target.value);
    setSearchTerm('');
  });

  return (
    <>
      <div className={styles.specialityContainer}>
        <div className={styles.speSelectedType}>
          {selectedSpeciality && (
            <div className={styles.selectedSpeciality}>
              <span>{specialities.find(speciality => speciality.id === selectedSpeciality).metier.toUpperCase()}</span>
              <button onClick={() => handleSpecialityChange(selectedSpeciality)} className={styles.clearButton}>
                <FaTimes />
              </button>
            </div>
          )}
          <select
            value={searchType} // "metier" ou "lieu"
            onChange={changeType}
            className={styles.searchType}
          >
            <option value="metier">🔧 Métier</option>
            <option value="lieu">🏙️ Lieu</option>
          </select>
        </div>
        <div ref={containerRef}>
          <input
            type="text"
            placeholder={`Recherche par ${searchType === 'metier' ? 'métier' : 'lieu'}...`}
            value={searchTerm}
            onChange={e => (searchType === 'metier' ? setSearchTerm(e.target.value) : handleInputLocChange(e))}
            onFocus={() => setIsFocused(true)}
            className={styles.searchInput}
          />
          {loading && <div className={styles.loading}>Chargement...</div>}
          <div className={`${styles.listSpeciality} ${isFocused ? styles.show : ''}`}>
            {searchType === 'metier' && filteredSpecialities.length > 0 && (
              <ul className={styles.suggestionsList}>
                {filteredSpecialities.map(speciality => (
                  <li
                    key={speciality.id}
                    onClick={() => handleSpecialityChange(speciality.id)}
                    className={`${styles.specialityButton} ${selectedSpeciality === speciality.id ? styles.selected : ''}`}
                  >
                    {speciality.metier.toUpperCase()}
                  </li>
                ))}
              </ul>
            )}

            {searchType !== 'metier' && suggestionsLoc.length > 0 && (
              <ul className={styles.suggestionsList}>
                {suggestionsLoc.map(suggestion => (
                  <li
                    key={suggestion.properties.id}
                    className={styles.specialityButton}
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion.properties.label.split(' ').length === 1
                      ? suggestion.properties.label + ', ' + suggestion.properties.postcode
                      : suggestion.properties.label}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      </div>

      <div className={styles.premiereDiv}>
        <div className={styles.mapContainer}>
          <MapContainer
            center={center}
            zoom={10}
            style={{ height: '100%', width: '100%' }}
            key={JSON.stringify(filteredArtisans)}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            {/* Utilisation du composant pour recentrer dynamiquement */}
            <RecenterAutomatically center={center} />
            <MarkerClusterGroup>
              {filteredArtisans &&
                filteredArtisans.map(artisan => (
                  <Marker key={artisan.id} position={[artisan.latitude, artisan.longitude]} icon={artisanIcon}>
                    <Popup>
                      <ArtisanPopup artisan={artisan} />
                    </Popup>
                  </Marker>
                ))}
            </MarkerClusterGroup>
          </MapContainer>
          {/* Bouton pour recentrer sur la localisation actuelle */}
          <button className={styles.locationButton} onClick={recenterMapOnLocation}>
            <FaLocationArrow size={20} />
          </button>
        </div>
      </div>
    </>
  );
};

export default LeafletMap;
