import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchSpecialities } from '../../redux/specialitiesSlice';
import styles from './NosArtisans.module.css';

const API_URL = import.meta.env.VITE_API_URL;

const NosArtisans = () => {
  const dispatch = useDispatch();
  const { specialities, isLoading, error } = useSelector(state => state.specialities);

  // Ne récupérer les spécialités que si elles ne sont pas déjà chargées
  useEffect(() => {
    if (!specialities || specialities.length === 0) {
      dispatch(fetchSpecialities());
    }
  }, [dispatch, specialities]);

  if (isLoading) return <div>Chargement en cours...</div>;
  if (error) return <div>Erreur de chargement des corps de métier</div>;

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Faites Confiance à des ARTISANS Vérifiés et Qualifiés</h1>
      <h2 className={styles.subtitle}>
        Nous sélectionnons les meilleurs artisans de votre région pour vous garantir un service de qualité
      </h2>

      {/* Affichage en grille de 4 cartes par ligne */}
      <div className={styles.grid}>
        {specialities?.map(speciality => (
          <SpecialityCard key={speciality.id} speciality={speciality} />
        ))}
      </div>
    </div>
  );
};

// Composant enfant pour chaque carte d'artisan
const SpecialityCard = memo(({ speciality }) => {
  return (
    <div className={styles.card}>
      <Link to={`/metier/${speciality.id}`}>
        {speciality && (
          <img
            className={styles.image}
            src={`${API_URL}/${speciality.pathImg}`}
            alt={speciality.metier}
            loading="lazy" // Lazy loading des images
          />
        )}
        <div className={styles.metier}>{speciality.metier}</div>
      </Link>
    </div>
  );
});

SpecialityCard.displayName = 'SpecialityCard';
SpecialityCard.propTypes = {
  speciality: PropTypes.shape({
    id: PropTypes.number.isRequired,
    pathImg: PropTypes.string,
    metier: PropTypes.string
  }).isRequired
};

export default NosArtisans;
