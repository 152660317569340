import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { uploadFileDetail } from '../../services/Api';
import styles from './HomeBackoffice.module.css';

const API_URL = import.meta.env.VITE_API_URL;

const DetailArtisanForm = ({ artisanId, detail, onSave, onDelete, isNew = false }) => {
  const [type, setType] = useState(detail.type || 'IMG_PRINCIPALE');
  const [description, setDescription] = useState(detail.description || '');
  const [pathImg, setPathImg] = useState(detail.pathImg || null);

  useEffect(() => {
    // Initialisation des valeurs de détail uniquement si elles changent
    setType(detail.type || 'IMG_PRINCIPALE');
    setDescription(detail.description || '');
    setPathImg(detail.pathImg || null);
  }, [detail]);

  // Gestion du changement de fichier pour les images
  const handleImageDrop = e => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        toast.error("La taille de l'image ne doit pas dépasser 2 Mo.");
        return;
      }
      // Appel API pour uploader le fichier et récupérer le chemin
      const formData = new FormData();
      formData.append('file', file);
      uploadFile(formData);
    }
  };

  const handleFileChange = e => {
    const file = e.target.files[0];
    if (file) {
      // Appel API pour uploader le fichier et récupérer le chemin
      const formData = new FormData();
      formData.append('file', file);
      uploadFile(formData);
    }
  };

  const uploadFile = async formData => {
    // Appel à l'API pour uploader le fichier sur le serveur
    try {
      const filePath = await uploadFileDetail(formData, artisanId);
      setPathImg(filePath); // Met à jour l'état avec le chemin du fichier
    } catch (error) {
      console.log(error);
      toast.error('Erreur lors du téléchargement du fichier.');
    }
  };

  const handleRemoveImage = () => {
    setPathImg(null);
  };

  // Gestion de la soumission du formulaire
  const handleSubmit = async event => {
    event.preventDefault();

    const updatedDetail = {
      ...detail,
      type,
      description: type === 'description' ? description : 'Normes ou réalisation',
      path: type !== 'description' ? pathImg : null
    };
    onSave(updatedDetail, isNew);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Link to="/backoffice/admin/artisans">
        <button className={styles.buttonList}>Retour</button>
      </Link>

      <div className={styles.formGroup}>
        <label>Type:</label>
        <select value={type} onChange={e => setType(e.target.value)}>
          <option value="IMG_PRINCIPALE">Image principale</option>
          <option value="IMG_REALISATION">Images secondaires</option>
          <option value="DESCRIPTION">Description</option>
          <option value="NORME">Qualification/Norme</option>
        </select>
      </div>

      {type === 'DESCRITPION' && (
        <div className={styles.formGroup}>
          <label>Description:</label>
          <textarea value={description} onChange={e => setDescription(e.target.value)} required />
        </div>
      )}

      {(type === 'IMG_PRINCIPALE' || type === 'IMG_REALISATION' || type === 'NORME') && (
        <div
          onDrop={handleImageDrop}
          onDragOver={e => e.preventDefault()}
          style={{
            border: '2px dashed #ccc',
            padding: '20px',
            textAlign: 'center',
            marginBottom: '20px'
          }}
        >
          {pathImg ? (
            <div>
              <img
                src={`${API_URL}/${pathImg}`}
                alt="Aperçu"
                style={{ maxWidth: '100%', maxHeight: '150px', marginBottom: '10px' }}
              />
              <button className={styles.buttonList} onClick={handleRemoveImage}>
                Supprimer l&apos;image
              </button>
            </div>
          ) : (
            <p></p>
          )}
          <input
            type="file"
            accept="image/*" // Limite le type de fichiers à des images
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id={`file-input-${detail.id || 'new'}`}
          />
          <label htmlFor={`file-input-${detail.id || 'new'}`} style={{ cursor: 'pointer' }}>
            Déposez une image ici ou cliquez pour sélectionner un fichier
          </label>
        </div>
      )}

      <div className={styles.buttonGroup}>
        <button className={styles.buttonList} type="submit">
          {isNew ? 'Ajouter Détail' : 'Enregistrer les modifications'}
        </button>
        {!isNew && (
          <button className={styles.buttonList} type="button" onClick={() => onDelete(detail.id)}>
            Supprimer
          </button>
        )}
      </div>
    </form>
  );
};

DetailArtisanForm.propTypes = {
  artisanId: PropTypes.number.isRequired,
  detail: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string,
    description: PropTypes.string,
    pathImg: PropTypes.string
  }),
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  isNew: PropTypes.bool
};

export default DetailArtisanForm;
