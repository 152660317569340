import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sendMailToRegister } from '../../services/Api';
import styles from './Join.module.css';

const Join = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nom: '',
    prenom: '',
    email: '',
    phone: '',
    message: ''
  });
  const [loading, setLoading] = useState(false);

  const handleChange = useCallback(e => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));
  }, []);

  const handleSubmit = useCallback(
    async e => {
      e.preventDefault();
      setLoading(true);

      try {
        await sendMailToRegister(formData);
        toast.success('Message envoyé avec succès !');
        setFormData({
          nom: '',
          prenom: '',
          email: '',
          phone: '',
          message: ''
        });
        navigate('/');
      } catch (error) {
        console.log(error);
        toast.error('Une erreur est survenue lors de l’envoi.');
      } finally {
        setLoading(false);
      }
    },
    [formData, navigate]
  );

  return (
    <div className={styles.joinFormContainer}>
      <StaticContent />
      <form onSubmit={handleSubmit} className={styles.form}>
        {[
          { label: 'Prénom', name: 'prenom', type: 'text', placeholder: 'Votre prénom' },
          { label: 'Nom', name: 'nom', type: 'text', placeholder: 'Votre nom' },
          { label: 'Email', name: 'email', type: 'email', placeholder: 'Votre email' },
          { label: 'Téléphone', name: 'phone', type: 'tel', placeholder: 'Votre n° de téléphone' }
        ].map(({ label, name, type, placeholder }) => (
          <div key={name} className={styles.formGroup}>
            <label className={styles.label} htmlFor={name}>
              {label}
            </label>
            <input
              id={name}
              name={name}
              type={type}
              placeholder={placeholder}
              value={formData[name]}
              onChange={handleChange}
              className={styles.input}
              required
            />
          </div>
        ))}
        <div className={styles.formGroup}>
          <label className={styles.label} htmlFor="message">
            Message
          </label>
          <textarea
            id="message"
            name="message"
            placeholder="Votre message"
            className={styles.input}
            value={formData.message}
            onChange={handleChange}
          />
        </div>
        <button className={styles.submitButton} type="submit" disabled={loading}>
          {loading ? 'Envoi en cours...' : 'Envoyer'}
        </button>
      </form>
    </div>
  );
};

const StaticContent = () => (
  <>
    <h2 className={styles.title}>Rejoignez Proxi Travaux</h2>
    <p className={styles.subtitle}>
      Intégrez un réseau de professionnels qualifiés et développez votre activité grâce à notre plateforme. Bénéficiez
      d&apos;une visibilité accrue et accédez à des projets adaptés à vos compétences.
    </p>
    <ul className={styles.benefitsList}>
      {[
        'Augmentez votre clientèle avec des projets ciblés.',
        'Bénéficiez d’un accompagnement personnalisé.',
        'Rejoignez une communauté d’artisans reconnus.'
      ].map((benefit, index) => (
        <li key={index} className={styles.benefitItem}>
          <span className={styles.icon}>✔</span> {benefit}
        </li>
      ))}
    </ul>
  </>
);

export default Join;
