import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getSpecialityById } from '../../services/Api';
import styles from './LeafletMap.module.css';

const ArtisanPopup = ({ artisan }) => {
  const [specialites, setSpecialites] = useState([]);

  useEffect(() => {
    const fetchSpecialites = async () => {
      const specialitesData = await Promise.all(artisan.specialites.map(id => getSpecialityById(id)));
      setSpecialites(specialitesData);
    };
    if (!specialites || specialites.length === 0) {
      fetchSpecialites();
    }
  }, [artisan.specialites]);

  return (
    <div className={styles.popupContainer}>
      <h3 className={styles.popupTitle}>{artisan.entrepriseNom}</h3>
      {artisan.anneeExperience && (
        <p>
          <strong>Années d&apos;expérience :</strong> {artisan.anneeExperience}
        </p>
      )}
      <p>
        <strong>Rayon d&apos;activité :</strong> {artisan.rayonActivite} km
      </p>
      <p>
        <strong>Adresse :</strong> {artisan.adresse.rue}, {artisan.adresse.ville}, {artisan.adresse.codePostal},{' '}
        {artisan.adresse.pays}
      </p>
      <p>
        <strong>Spécialités :</strong>{' '}
        {specialites.length > 0 ? specialites.map(spec => spec.metier).join(', ') : 'Aucune spécialité'}
      </p>
      <Link to={`/artisan/${artisan.id}`} className={styles.artisanButton}>
        Voir Artisan
      </Link>
    </div>
  );
};

ArtisanPopup.propTypes = {
  artisan: PropTypes.shape({
    id: PropTypes.number.isRequired,
    entrepriseNom: PropTypes.string.isRequired,
    anneeExperience: PropTypes.string,
    adresse: PropTypes.shape({
      rue: PropTypes.string,
      ville: PropTypes.string,
      codePostal: PropTypes.string,
      pays: PropTypes.string
    }),
    rayonActivite: PropTypes.string,
    siret: PropTypes.string,
    siren: PropTypes.string,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
    specialites: PropTypes.arrayOf(PropTypes.number),
    notes: PropTypes.arrayOf(PropTypes.number),
    details: PropTypes.arrayOf(PropTypes.number)
  }).isRequired
};

export default ArtisanPopup;
